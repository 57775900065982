<template>
    <b-row>
        <b-col cols="12">
            <b-row>
                <b-col cols="12" md="4">
                    <b-card no-body class="border-primary">
                        <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
                            <h5 class="mb-0">
                                Current Plan
                            </h5>
                            <b-badge v-if="!isEmpty(activeSubscription) && !isEmpty(activeSubscription.plan)"
                                variant="light-primary">
                                {{ activeSubscription.plan.package_name }} - ${{ activeSubscription.plan.package_amount }}
                            </b-badge>
                            <b-badge v-else variant="light-danger">
                                NO ACTIVE PLAN
                            </b-badge>
                            <small v-if="!isEmpty(activeSubscription) && !isEmpty(activeSubscription.plan)" class="text-muted w-100">
                                {{ dateTimeFormat(activeSubscription.start_date, "MMM D, YYYY") }}
                            </small>
                        </b-card-header>

                        <b-card-body style="min-height: 200px">
                            <template v-if="!isEmpty(activeSubscription)">
                                <UserSubscriptionUsage v-if="packageFor == 'USER'" :activeSubscription="activeSubscription" />
                                <StoreProviderSubscriptionUsage v-else :activeSubscription="activeSubscription" />
                            </template>
                            <b-button class="my-2" variant="primary" block @click="upgradePlanModelState = true">
                                Upgrade Plan
                            </b-button>
                            <b-row class="mt-2">
                                <b-col cols="12">
                                    <b-form-input v-model="promoCode" :placeholder="$t('Enter Promo Code')" />
                                </b-col>
                                <b-col cols="12" class="mt-1">
                                    <b-button variant="primary" block size="sm" @click.prevent="applyPromocode" type="button">Apply</b-button>
                                </b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>
                </b-col>
                <!-- <b-col cols="12" md="8">
                    <b-card no-body class="border-primary">
                        <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
                            <h5 class="mb-0">
                                Payment Methods
                            </h5>
                            <small class="text-muted w-100"></small>
                        </b-card-header>
                        <b-card-body v-if="!isEmpty(paymentMethods)">
                            <ul class="list-unstyled my-1">
                                <li v-for="(pm, pmk) in paymentMethods" :key="`pm_${pmk}`" class="mb-1">
                                    <span class="align-middle font-16">
                                        <div v-if="pm.type === 'card'">
                                            <b-icon icon="credit-card" class="mr-1"></b-icon>
                                            <strong class="text-capitalize mr-2">{{ pm.card.brand }}</strong> <span
                                                class="text-muted">XXXX</span>{{ pm.card.last }}
                                            <span class="ml-1 text-muted">(Exp: {{ pm.card.exp_month }}/{{ pm.card.exp_year
                                            }})</span>
                                        </div>
                                    </span>
                                </li>
                            </ul>
                        </b-card-body>
                        <b-card-body v-else class="d-flex justify-content-center align-items-center"
                            style="min-height: 200px">
                            <h3 class="text-muted">No Payment methods found</h3>
                        </b-card-body>
                    </b-card>
                </b-col> -->
            </b-row>
            <b-row>
                <b-col :cols="12">
                    <h2>Subscription History</h2>
                    <b-table striped hover responsive :items="userSubscribedServices" :fields="subscriptionColumns">
                        <template #cell(action)="{item}">
                            <template v-if="item.subscription_status == 3">
                                <b-button size="sm" variant="danger" @click="raiseCancelSubscription(item.id)" class="mr-2">
                                    Cancel
                                </b-button>
                            </template>
                            <template v-else-if="item.subscription_status == 4">
                                <b-button size="sm" variant="warning" @click="cancelCancelSubscriptionRequest(item.id)" class="mr-2">
                                    Stop
                                </b-button>
                            </template>
                            <template v-else>
                                ---
                            </template>
                        </template>
                    </b-table>
                </b-col>
            </b-row>
        </b-col>
        <button id="rzp-button" style="height:.5px;width:.5px;visibility:hidden">.</button>
        <!-- basic modal -->
        <b-modal v-model="upgradePlanModelState" size="xl" centered :title="$t('Choose Plan')" hide-footer>
            <SubscriptionList :subscriptions="subscriptions" @upgradeSubscription="updradePlan" :allowUpgrade="true" :upgradeBtn="`Subscribe`" />
        </b-modal>
        <StripePayment ref="stripePayment" />
    </b-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { isEmpty, dateTimeFormat } from '@/utilities'
import { hideLoader, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import SubscriptionList from '@/views/shared/subscription/SubscriptionList.vue'
import StoreProviderSubscriptionUsage from '@/views/shared/subscription/StoreProviderSubscriptionUsage.vue'
import UserSubscriptionUsage from '@/views/shared/subscription/UserSubscriptionUsage.vue'
import StripePayment from '@/views/shared/subscription/StripePayment.vue'
const subscriptionColumns = [
    {
        key: 'plan_name',
        label: 'Plan',
        sortable: false,
    },
    {
        key: 'plan_amount',
        label: 'Amount',
        sortable: false,
    },
    {
        key: 'start_date',
        label: 'Start Date',
        sortable: false,
    },
    {
        key: 'end_date',
        label: 'End Date',
        sortable: false,
    },
    {
        key: 'status_txt',
        label: 'Subscription Status',
        sortable: false,
    },
    {
        key: 'action',
        label: 'Action',
        sortable: false,
    },
]
export default {
    name: "SubscriptionTab",
    components: {
        SubscriptionList, StripePayment, StoreProviderSubscriptionUsage, UserSubscriptionUsage
    },
    props: {
        packageFor: {
            type: String,
            required: true
        }
    },
    data: () => ({
        upgradePlanModelState: false,
        promoCode: null,
        capturePaymentData: {
            razorpay: {}
        },
        subscriptionColumns
    }),
    computed: {
        ...mapGetters('subscriptionPlan', [
            'isSubscriptionLoaded',
            'storeSubscriptions',
            'providerSubscriptions',
            'userSubscriptions',
        ]),
        ...mapGetters('paymentGateway', [
            'isPaymentGatewaysLoaded',
        ]),
        ...mapGetters('subscription', [
            'subscriptionById',
            'paymentMethodsById',
        ]),
        userInfo: {
            get() {
                return window.SuperApp.getters.userInfo()
            }
        },
        userSubscribedServices: {
            get() {
                if (!isEmpty(this.packageFor) && !isEmpty(this.userInfo)) {
                    return this.subscriptionById(this.packageFor, this.userInfo.id)
                }
                return []
            }
        },
        activeSubscription: {
            get() {
                const activeSubscription = this.userSubscribedServices.find(i => i.isActive)
                return activeSubscription
            }
        },
        upcomingSubscription: {
            get() {
                return this.userSubscribedServices.find(i => i.subscription_status == 3)
            }
        },
        /*
        paymentMethods: {
            get() {
                // if (!isEmpty(this.packageFor) && !isEmpty(this.userInfo)) {
                //     return this.paymentMethodsById(this.packageFor, this.userInfo.id)
                // }
                return []
            }
        },
        */
        subscriptions: {
            get() {
                if (isEmpty(this.packageFor)) return []
                let subscriptions = [] 
                switch (this.packageFor) {
                    case 'STORE':
                        subscriptions = this.storeSubscriptions
                        break;
                    case 'PROVIDER':
                        subscriptions = this.providerSubscriptions
                        break;
                    case 'USER':
                        subscriptions = this.userSubscriptions
                        break;
                
                    default:
                        break;
                }
                if (!isEmpty(this.activeSubscription)) {
                    return subscriptions.map(i => {
                        const isActive = i.uuid === this.activeSubscription.sp_uuid
                        return {
                            ...i,
                            isActive
                        }
                    })
                }
                return subscriptions
            }
        },
        isStore() {
            return window.SuperApp.getters.isStore()
        },
        isProvider() {
            return window.SuperApp.getters.isProvider()
        },
        isUser() {
            return window.SuperApp.getters.isUser()
        },
    },
    created: () => {

    },
    beforeMount: () => {

    },
    mounted: async function () {
        if (!this.isPaymentGatewaysLoaded) {
            this.getPaymentGateways()
        }
        if (!this.isSubscriptionLoaded) {
            this.getSubscriptionPackages()
        }
        if (this.$route.query.payment_intent_client_secret) {
            const clientSecret = this.$route.query.payment_intent_client_secret
            await this.verifyStripePayment(clientSecret)
        }
        this._userSubscriptions()
    },
    methods: {
        isEmpty, dateTimeFormat,
        ...mapActions('subscriptionPlan', [
            'getSubscriptionPackages',
        ]),
        ...mapActions('paymentGateway', [
            'getPaymentGateways',
        ]),
        ...mapActions('subscriptionService', [
            'initCheckout',
            'verifyPayment',
            'raiseCancelSubscription',
            'cancelCancelSubscriptionRequest',
        ]),
        ...mapActions('subscriptionService', {
            _userSubscriptions: 'userSubscriptions'
        }),
        ...mapActions('coupon', [
            'userApplyCode',
            'storeApplyCode',
            'providerApplyCode',
        ]),
        async updradePlan(subscription, gatewayObj) {
            localStorage.removeItem('new_sp_uuid');
            localStorage.removeItem('payment_gateway_id');
            localStorage.removeItem('stripe_pkey');
            const { gateway, id } = gatewayObj

            showLoader()
            let payLoad = {
                uuid: subscription.uuid,
                payment_gateway: id
            }
            this.initCheckout(payLoad).then(r => {
                this.upgradePlanModelState = false
                const { data, status } = r
                const userSubscriptions = this._userSubscriptions
                const thisClass = this
                if(status === 202){
                    showSuccessNotification(this, data.message)
                    userSubscriptions()
                }else if (status === 200) {
                    localStorage.setItem('new_sp_uuid', subscription.uuid);
                    localStorage.setItem('payment_gateway_id', id);
                    const { responseData: paymentRes } = data
                    const { plan } = paymentRes
                    const { package_name } = plan
                    let prefill = {
                        "name": `${this.userInfo.store_name}`,
                        "email": this.userInfo.email,
                        "contact": this.userInfo.contact_number
                    }
                    if (this.packageFor == 'PROVIDER' || this.packageFor == 'USER') {
                        prefill = {
                            "name": `${this.userInfo.first_name} ${this.userInfo.last_name}`,
                            "email": this.userInfo.email,
                            "contact": this.userInfo.mobile
                        }
                    }
                    const amount = parseInt(paymentRes.amount)
                    switch (gateway) {
                        case 'razorpay':
                            this.capturePaymentData.razorpay = {
                                key: paymentRes.pkey,
                                amount: amount * 100,
                                currency: "USD",
                                name: package_name,
                                description: package_name,
                                image: "https://example.com/your_logo",
                                order_id: paymentRes.id,
                                handler: function (response) {
                                    payLoad.checkout_id = response.razorpay_payment_id
                                    thisClass.verifyPayment(payLoad).then(async rr => {
                                        await userSubscriptions()
                                        showSuccessNotification(thisClass, rr.data.message)
                                    }).catch(ee => {
                                        showErrorNotification(thisClass, "Error")
                                    })
                                },
                                prefill,
                                notes: {
                                    address: "Razorpay Corporate Office"
                                },
                                theme: {
                                    color: "#7367f0"
                                }
                            };
                            var rzp1 = new Razorpay(this.capturePaymentData.razorpay);
                            rzp1.on('payment.failed', function (response) {
                                alert(response.error.code);
                                alert(response.error.description);
                                alert(response.error.source);
                                alert(response.error.step);
                                alert(response.error.reason);
                                alert(response.error.metadata.order_id);
                                alert(response.error.metadata.payment_id);
                            });
                            document.getElementById('rzp-button').onclick = function (e) {
                                rzp1.open();
                                e.preventDefault();
                            }
                            document.getElementById('rzp-button').click()
                            break;

                        case 'stripe':
                            localStorage.setItem('stripe_pkey', paymentRes.pkey);
                            this.$refs.stripePayment.initStripeKey(paymentRes.pkey, paymentRes.client_secret)
                            break;

                        case 'paypal':
                            window.location.href = paymentRes.href
                            break;
                        default:
                            break;
                    }
                }
                hideLoader()
            }).catch(e => {
                showErrorNotification(this, e)
                hideLoader()

            })
        },
        applyPromocode() {
            if (isEmpty(this.promoCode)) return;
            let applyCodeFn = null
            switch (this.packageFor) {
                case "STORE":
                    applyCodeFn = this.storeApplyCode
                    break;
                case "PROVIDER":
                    applyCodeFn = this.providerApplyCode
                    break;
                case "USER":
                    applyCodeFn = this.userApplyCode
                    break;
            
                default:
                    break;
            }

            if(typeof applyCodeFn === 'null') return false;

            showLoader()
            applyCodeFn(this.promoCode).then(async r => {
                this.$emit('subscriptionUpdated', true)
                await this.userSubscriptions()
                showSuccessNotification(this, 'Coupon Applied successfully!')
                hideLoader()
            }).catch(e => {
                showErrorNotification(this, e)
                hideLoader()
            })
        },
        verifyStripePayment: async function (clientSecret) {
            const new_sp_uuid = localStorage.getItem('new_sp_uuid');
            const payment_gateway_id = localStorage.getItem('payment_gateway_id');
            const stripe_pkey = localStorage.getItem('stripe_pkey');

            if (!isEmpty(new_sp_uuid)) {
                const stripe = window.Stripe(stripe_pkey)
                const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
                switch (paymentIntent.status) {
                    case "succeeded":
                        const payLoad = {
                            uuid: new_sp_uuid,
                            payment_gateway: payment_gateway_id,
                            checkout_id: this.$route.query.payment_intent_client_secret,
                        }
                        localStorage.removeItem('new_sp_uuid');
                        localStorage.removeItem('payment_gateway_id');
                        localStorage.removeItem('stripe_pkey');
                        await this.verifyPayment(payLoad).then(r => {
                            showSuccessNotification(this, this.$t('Service Subscribed Successfully!'))
                        }).catch(e => {

                        })
                        break;
                    case "processing":
                        showErrorNotification(this, "Your payment is processing.");
                        break;
                    case "requires_payment_method":
                        showErrorNotification(this, "Your payment was not successful, please try again.");
                        break;
                    default:
                        showErrorNotification(this, "Something went wrong.");
                        break;
                }
            }
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
</style>