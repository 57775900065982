<template>
    <div v-if="step === 1">
        <div class="row d-md-flex justify-content-center">
            <div class="col-12 my-2 d-flex justify-content-center">
                <b-form-radio-group
                    id="btn-radios-2"
                    v-model="tenureType"
                    :options="[
                        {text: 'Monthly', value: 'MONTH'},
                        {text: 'Yearly', value: 'YEAR'}
                    ]"
                    button-variant="outline-primary"
                    name="radio-btn-outline"
                    buttons
                />
            </div>
            <div class="col-12 col-md-3 price_card py-3" v-for="subscription in activeSubscriptions" :key="subscription.uuid">
                <b-img v-if="!isEmpty(subscription.image)" height="200" :src="subscription.image" class="mb-1"
                    :alt="subscription.package_name" />
                <h3><b>{{ subscription.package_name }}</b></h3>
                <p class="text-muted">
                    {{ subscription.description }}
                </p>

                <p class="text-muted">
                    Starting at
                </p>
                <div class="d-flex justify-content-start align-items-center">
                    <div class="mr-3px plan_price">{{ subscription.package_amount }}</div>
                    <div>
                        <p class="text-muted mb-0 mt-0 small_line_height">Per</p>
                        <p class="text-muted mb-0 mt-0 small_line_height">
                            <template v-if="tenureType == 'MONTH'">Month</template>
                            <template v-else>Year</template>
                        </p>
                    </div>
                </div>
                <p class="text-muted">
                    Billed monthly based on usage
                </p>

                <div v-if="!isEmpty(subscription.features)" class="mt-2">
                    <p class="text-muted mb-0">
                        This includes:
                    </p>
                    <div v-for="(feature, fk) in subscription.features" :key="`feature_${fk}`" class="mb-3px">
                        <b-icon :icon="feature.enabled ? 'check-circle-fill' : 'x-circle-fill'"
                            :class="feature.enabled ? 'text-success' : 'text-danger'"></b-icon> {{ feature.label }}
                    </div>
                </div>

                <b-button block class="mt-2" variant="primary" @click="upgrade(subscription)" :disabled="subscription.isActive">
                    <template v-if="subscription.isActive">Current Plan</template>
                    <template v-else>{{ upgradeBtn }}</template>
                </b-button>
            </div>
        </div>
    </div>
    <div v-else-if="step === 2">
        <div class="row d-md-flex justify-content-center">
            <div class="col-12 my-2 d-flex justify-content-center">
                <b-form-radio-group
                    id="btn-radios-2"
                    v-model="paymentGateway"
                    :options="paymentGateways"
                    button-variant="outline-success"
                    name="radio-btn-outline"
                    buttons
                />
            </div>
            <div class="my-2 col-12 d-flex justify-content-between">
                <b-button variant="outline-primary" @click="step--">
                    <template>Back</template>
                </b-button>
                <b-button variant="success" @click="upgradeNow" :disabled="isEmpty(paymentGateway)">
                    <template>Procceed</template>
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
import { isEmpty } from '@/utilities';
import { BButton, BRow, BCol, BImg, BFormRadioGroup } from 'bootstrap-vue'
import { mapGetters } from 'vuex';

export default {
    name: "SubscriptionList",
    components: {
        BButton, BRow, BCol, BImg, BFormRadioGroup
    },
    props: {
        subscriptions: Array,
        upgradeBtn: {
            type: String,
            default: 'Upgrade'
        },
        allowUpgrade: {
            type: Boolean,
            default: false
        },
    },
    data: () => ({
        tenureType: 'MONTH',
        step: 1,
        subscription: {},
        paymentGateway: null,
    }),
    computed: {
        ...mapGetters('paymentGateway', [
            'activePaymentGateways',
        ]),
        paymentGateways: {
            get(){
                console.log(this.activePaymentGateways, 'this.activePaymentGateways');
                return this.activePaymentGateways.map(pg => ({
                    text: pg.gateway.toUpperCase(),
                    value: pg.id,
                }))
            }
        },
        activeSubscriptions: {
            get(){
                return this.subscriptions.filter(i => i.status && i.tenure_type == this.tenureType)
            }
        }
    },
    methods: {
        isEmpty,
        upgrade(subscription){
            if(this.allowUpgrade){
                this.subscription = subscription
                if(this.subscription.package_amount > 0){
                    this.step++
                }else{
                    const pg = this.activePaymentGateways.find((i,idx) => idx === 0)
                    this.$emit('upgradeSubscription', this.subscription, pg)
                }
            }else{
                this.$emit('upgradeSubscription', subscription, null)
            }
        },
        upgradeNow(){
            const pg = this.activePaymentGateways.find(i => i.id == this.paymentGateway)
            this.$emit('upgradeSubscription', this.subscription, pg)
        }
    }
}
</script>

<style lang="scss" scoped>
.plan_price::before {
  content: '$',
}

.row, p{
    font-size: 1rem !important;
}
.row{
    text-align: left;
}
.plan_price{
    font-size: 3.5rem;
}
.small_line_height{
  line-height: 1;
  font-size: 1.1rem !important;
}
.mb-3px{
  margin-bottom: 5px;
  .b-icon{
    margin-right: 3px;
  }
}
.mr-3px{
  margin-right: 3px;
}
</style>