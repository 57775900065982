<template>
  <component
    :is="userData === undefined ? 'div' : 'b-card'"
    class="user-profile"
  >
    <b-tabs
      v-if="userData"
      v-model="activeTab"
      pills
      class="user-profile-info"
    >
      <!-- Tab: Account -->
      <b-tab>
        <template #title>
          <!-- <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          /> -->
          <b-img
            :src="require('@/assets/images/app-icons/user/account.png')"
            class="mr-0 mr-sm-50 profile-img-height"
          />
          <span class="d-none d-sm-inline">{{ $t('Account') }}</span>
        </template>
        <user-edit-tab-account
          ref="userEditTabAccount"
          :country-info="countryInfo"
          :user-data="userData"
          class="mt-2 pt-75"
        />
      </b-tab>

      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <!-- <feather-icon
            icon="KeyIcon"
            size="16"
            class="mr-0 mr-sm-50"
          /> -->
          <b-img
            :src="require('@/assets/images/app-icons/user/reset_password.png')"
            class="mr-0 mr-sm-50 profile-img-height"
          />
          <span class="d-none d-sm-inline">{{ $t('Change Password') }}</span>
        </template>
        <user-edit-change-password class="mt-2 pt-75" />
      </b-tab>

      <!-- <b-tab>
        <template #title>
          <feather-icon
            icon="KeyIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <b-img
            :src="require('@/assets/images/app-icons/user/Payment_methods.png')"
            class="mr-0 mr-sm-50 profile-img-height"
          />
          <span class="d-none d-sm-inline">{{ $t('Payment Methods') }}</span>
        </template>
        <user-edit-payment-method class="mt-2 pt-75" />
      </b-tab> -->

      <b-tab>
        <template #title>
          <b-img
            :src="require('@/assets/images/app-icons/user/subscription.png')"
            class="mr-0 mr-sm-50 profile-img-height"
          />
          <span class="d-none d-sm-inline">{{ $t('Subscription') }}</span>
        </template>
        <SubscriptionTab 
          :packageFor="`USER`"
          class="mt-3"
        />
      </b-tab>

      <!-- Tab: Social -->
      <b-tab>
        <template #title>
          <!-- <feather-icon
            icon="HomeIcon"
            size="16"
            class="mr-0 mr-sm-50"
          /> -->
          <b-img
            :src="require('@/assets/images/app-icons/user/address.png')"
            class="mr-0 mr-sm-50 profile-img-height"
          />
          <span class="d-none d-sm-inline">{{ $t('Address') }}</span>
        </template>
        <user-edit-address-card
          :address-info="addressInfo"
          class="mt-2 pt-75"
          @add-new-address="addNewAddress"
          @delete-address="deleteAddress"
        />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import { hideLoader, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import { useUserUi } from './useUser'
import UserEditTabAccount from './profile/UserEditTabAccount.vue'
import UserEditTabInformation from './profile/UserEditTabInformation.vue'
import UserEditTabSocial from './profile/UserEditTabSocial.vue'
import UserEditChangePassword from './profile/UserEditChangePassword.vue'
import UserEditAddressCard from './profile/UserEditAddressCard.vue'
import UserEditPaymentMethod from './profile/UserEditPaymentMethod.vue'
import SubscriptionTab from '@/views/shared/subscription/SubscriptionTab.vue'
import { mapMutations } from 'vuex'

export default {
  components: {
    UserEditTabAccount,
    UserEditTabInformation,
    UserEditTabSocial,
    UserEditChangePassword,
    UserEditAddressCard,
    UserEditPaymentMethod,
    SubscriptionTab,
  },
  data() {
    return {
      userData: null,
      countryInfo: [],
      addressInfo: [],
      activeTab: 0,
    }
  },
  mounted() {
    const { countryList } = useUserUi()
    showLoader()

    countryList()
      .then(({ data }) => {
        if (data.statusCode === '200') {
          this.countryInfo = data.responseData.map(x => ({
            label: x.country_name,
            value: x.id,
          }))
        }
      })
      .catch(error => {
        showErrorNotification(this, error)
      })
    this.getUserProfile()
    this.userAddresses()
    if(this.$route.query.tab){
      const tab = this.$route.query.tab
      let activeTab = 0
      switch (tab) {
        case 'subscription':
          activeTab = 2
          break;
        case 'profile':
          activeTab = 0
          break;
      
        default:
          activeTab = 0
          break;
      }
      this.activeTab = activeTab
    }
  },
  methods: {
    ...mapMutations('subscription', ['importFromUser']),
    getUserProfile() {
      const { userProfile } = useUserUi()
      const self = this
      userProfile()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.importFromUser(data.responseData)
            if (!data.responseData.state_id) {
              // eslint-disable-next-line no-param-reassign
              data.responseData.state_id = ''
            }
            this.userData = data.responseData
            setTimeout(() => {
              self.$refs.userEditTabAccount.onCountryChange()
              if (self.$refs.userEditTabAccount && self.$refs.userEditTabAccount.$refs && self.$refs.userEditTabAccount.$refs.countryCodeRef) {
                window.SuperApp.helper.setCountryCode(self.$refs.userEditTabAccount.$refs.countryCodeRef, self.userData.country_code, self.userData.mobile)
              }
            }, 250)
            hideLoader()
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    userAddresses() {
      const { userAddressInfo } = useUserUi()
      userAddressInfo()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.addressInfo = data.responseData
            this.addressInfo.forEach((item, index) => {
              if (!item.building) {
                this.addressInfo[index].building = ''
              }
            })
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    deleteAddress(id) {
      showLoader()
      const { userDeleteAddress } = useUserUi()
      userDeleteAddress(id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.userAddresses()
            showSuccessNotification(this, data.message)
            hideLoader()
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    addNewAddress(address) {
      const { userAddNewAddress, updateUserAddress } = useUserUi()
      const formData = new FormData()
      formData.append('id', address.id)
      formData.append('map_address', address.map_address)
      formData.append('latitude', address.latitude)
      formData.append('longitude', address.longitude)
      formData.append('flat_no', address.flat_no)
      formData.append('street', address.street)
      formData.append('address_type', address.address_type)
      formData.append('building', address.building)
      formData.append('zipcode', address.zipcode)
      const callType = address.id ? updateUserAddress : userAddNewAddress
      if (address.id) {
        formData.append('_method', 'PATCH')
      }
      showLoader()
      callType(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.userAddresses()
            showSuccessNotification(this, data.message)
            hideLoader()
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style>
.user-profile-info ul.nav.nav-pills {
  justify-content: center;
}

.profile-img-height{
	width: 32px !important;
	height: 32px !important;
}
@media only screen and (max-width: 500px) {
  .profile-img-height{
    width: 16px !important;
    height: 16px !important;
  }
}
</style>
